// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible-section.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex; /* restrict toggleable area */
  align-items: center;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
}

.collapsible-section .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}

.collapsible-section .ant-collapse-item {
  border: 0;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/CollapsibleSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,6BAA6B;EAC5C,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".collapsible-section.ant-collapse > .ant-collapse-item > .ant-collapse-header {\n  display: flex; /* restrict toggleable area */\n  align-items: center;\n  font-size: 16px;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.collapsible-section .ant-collapse-content-box {\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.collapsible-section .ant-collapse-item {\n  border: 0;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
