// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stage-transition-dropdown .ant-tag {
    cursor: pointer;
    border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/model-registry/components/ModelStageTransitionDropdown.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".stage-transition-dropdown .ant-tag {\n    cursor: pointer;\n    border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
