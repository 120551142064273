// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-note-actions {
  margin-top: 16px;
}

.editable-note-actions button + button {
  margin-left: 16px;
}

.mde-header {
  background: var(--secondary-background-color);
}
`, "",{"version":3,"sources":["webpack://./src/common/components/EditableNote.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".editable-note-actions {\n  margin-top: 16px;\n}\n\n.editable-note-actions button + button {\n  margin-left: 16px;\n}\n\n.mde-header {\n  background: var(--secondary-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
