// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!../node_modules/antd/dist/antd.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./antd-override.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./common/index.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./model-registry/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAKA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import '~antd/dist/antd.css';\n@import 'antd-override.css';\n@import 'common/index.css';\n@import 'model-registry/index.css';\n\nbody {\n  margin: 0;\n  padding: 0;\n}\n\n#root {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
