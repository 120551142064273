// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-version-update-form .ant-form-item {
    margin-bottom: 0;
}

.model-version-update-form .ant-form-item-label {
    width: 100%;
    text-align: left;
}

.model-version-update-form div[role="status"][class^="css-"] {
    margin-right: -0.5px;
}
`, "",{"version":3,"sources":["webpack://./src/model-registry/components/DirectTransitionForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".model-version-update-form .ant-form-item {\n    margin-bottom: 0;\n}\n\n.model-version-update-form .ant-form-item-label {\n    width: 100%;\n    text-align: left;\n}\n\n.model-version-update-form div[role=\"status\"][class^=\"css-\"] {\n    margin-right: -0.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
