import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const ErrorBoundary = ({ error, errorInfo }: { error: Error; errorInfo: React.ErrorInfo }) => (
  <div>
    <h2>Something went wrong.</h2>
    <details style={{ whiteSpace: 'pre-wrap' }}>
      {error && error.toString()}
      <br />
      {errorInfo.componentStack}
    </details>
  </div>
);

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <ErrorBoundary error={err} errorInfo={info} />;
  },
  renderType: 'createRoot'
});

export const { bootstrap, mount, unmount } = lifecycles;
