// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-version-table {
  margin-bottom: 25px;
}

.model-version-table .model-version-link {
  cursor: pointer;
}

.model-version-table .model-version-status-icon {
  font-size: 13px;
}

.model-version-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.table-endpoint-container {
  width: 200px;
  max-width: 200px;
}

.table-endpoint-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display:block;
  overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/model-registry/components/ModelVersionTable.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".model-version-table {\n  margin-bottom: 25px;\n}\n\n.model-version-table .model-version-link {\n  cursor: pointer;\n}\n\n.model-version-table .model-version-status-icon {\n  font-size: 13px;\n}\n\n.model-version-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {\n  display: none;\n}\n\n.table-endpoint-container {\n  width: 200px;\n  max-width: 200px;\n}\n\n.table-endpoint-text {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  display:block;\n  overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
