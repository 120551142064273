// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* >>> Extract to our own Alert wrapper component */
.status-alert {
    margin-bottom: 16px;
    border-radius: 2px;
}

.status-alert .model-version-status-icon {
    margin-left: -3px;
}

.status-alert.status-alert-info .icon-pending {
    color: green;
    margin-right: 4px;
}

.status-alert.status-alert-info {
    border-left: 2px solid #3895d3;
}

.status-alert.status-alert-error {
    border-left: 2px solid red;
}


.breadcrumb-header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.breadcrumb-header {
    font-size: 18px;
    margin-bottom: 24px;
    font-weight: bold;
    color: #333;
    display:flex;
    align-items: center;
    margin-right: 10px;
}

.version-follow-icon {
    margin-left: auto;
}

.ant-popover-content {
    max-width: 500px;
}
/* <<< Extract to our own Alert wrapper component */
`, "",{"version":3,"sources":["webpack://./src/model-registry/components/ModelVersionView.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,0BAA0B;AAC9B;;;AAGA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;AACA,mDAAmD","sourcesContent":["/* >>> Extract to our own Alert wrapper component */\n.status-alert {\n    margin-bottom: 16px;\n    border-radius: 2px;\n}\n\n.status-alert .model-version-status-icon {\n    margin-left: -3px;\n}\n\n.status-alert.status-alert-info .icon-pending {\n    color: green;\n    margin-right: 4px;\n}\n\n.status-alert.status-alert-info {\n    border-left: 2px solid #3895d3;\n}\n\n.status-alert.status-alert-error {\n    border-left: 2px solid red;\n}\n\n\n.breadcrumb-header-wrapper {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.breadcrumb-header {\n    font-size: 18px;\n    margin-bottom: 24px;\n    font-weight: bold;\n    color: #333;\n    display:flex;\n    align-items: center;\n    margin-right: 10px;\n}\n\n.version-follow-icon {\n    margin-left: auto;\n}\n\n.ant-popover-content {\n    max-width: 500px;\n}\n/* <<< Extract to our own Alert wrapper component */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
