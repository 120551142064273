// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./components/ModelVersionTable.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./components/ModelVersionView.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./components/ModelStageTransitionDropdown.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[1]!../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[6].use[2]!./components/DirectTransitionForm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** TODO(Zangr) migrate globally common components and styles into src/common folder */
.metadata-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.metadata-entry {
  margin-bottom: 16px;
}

.icon-pending,
.icon-ready {
  color: green;
}

.icon-fail {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/model-registry/index.css"],"names":[],"mappings":"AAKA,sFAAsF;AACtF;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import 'components/ModelVersionTable.css';\n@import 'components/ModelVersionView.css';\n@import 'components/ModelStageTransitionDropdown.css';\n@import 'components/DirectTransitionForm.css';\n\n/** TODO(Zangr) migrate globally common components and styles into src/common folder */\n.metadata-container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.metadata-entry {\n  margin-bottom: 16px;\n}\n\n.icon-pending,\n.icon-ready {\n  color: green;\n}\n\n.icon-fail {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
